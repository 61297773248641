function DocInfo(propiedades) {

return `
Gracias por Explorar las Posibilidades con RenovArte

Las propiedades de la imagen que acabas de bajar son :
 {
    aspectRatio: ${propiedades.aspectRatio}
    width: ${propiedades.width}
    height: ${propiedades.height}
    name: ${propiedades.name}
    size: ${propiedades.size}
 }

En Tunueva.casa, estamos comprometidos con la innovación continua y la creación de herramientas 
que enriquezcan y transformen cada rincón de tu hogar.
Apreciamos profundamente tu interés y entusiasmo por reimaginar espacios junto a nosotros.

Tu Creatividad, Nuestro Compromiso

Si tienes ideas o funcionalidades que te gustaría ver en RenovArte, te invitamos a compartir tus sugerencias. 
Estamos constantemente buscando nuevas inspiraciones que reflejen las necesidades y sueños de nuestra comunidad. 
Las propuestas más populares no solo serán consideradas para futuras actualizaciones, 
sino que también tendrán prioridad en nuestro proceso de desarrollo.

Participa y Gana con Tus Ideas

Al postular tus ideas, no solo contribuyes al enriquecimiento de RenovArte, sino que también te haces elegible 
para participar en sorteos de experiencias gastronómicas y vinícolas únicas. 
Es nuestra manera de agradecer tu participación activa y celebrar la creatividad que cada uno aporta a nuestra comunidad.

¡La Aventura Continúa!

Estamos en el umbral de nuevas oportunidades y emocionantes desarrollos. Mantente conectado y forma parte de esta evolución constante. 
La aventura de transformar espacios acaba de comenzar, y tu voz es esencial en cada paso que damos hacia el futuro.

Innova y Transforma con Nosotros

Para postular tus ideas y descubrir consejos para maximizar tu experiencia con RenovArte, visita los siguientes enlaces:

Postula tus ideas aquí: [Enlace de postulación muy pronto]

Consejos para mejores resultados con RenovArte: [Enlace de consejos muy pronto]
`
;

}
export default DocInfo;