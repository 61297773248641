import React from 'react';
import { useNavigate, } from 'react-router-dom';
import {AppBar, Container, Toolbar, Button, IconButton, Box, Link, useMediaQuery} from '@mui/material';
import ThemeColor from '../../../components/Theme/Theme';
import { useLocation } from 'react-router-dom';
import LogoIcon from '../../../layouts/FullLayout/Logo/LogoIcon';
import LoginIcon from '@mui/icons-material/Login';

const AppBarContent = () =>{
    const vista =["Tunueva.casaInfo", "AmicInfo", "VipInfo"];
    const location = useLocation();
    const isMobile = useMediaQuery('(max-width:600px)')
    const currentView = location.pathname.split('/')[1];
    const ShowButton = vista.includes(currentView);
    const navigate = useNavigate();

    
    const handleLogin = () =>{
        if(currentView === "Tunueva.casaInfo"){
            navigate("/Login?vista=Tunueva.casa");

        }else if(currentView === "AmicInfo"){
             navigate("/Login?vista=AMIC");
            
        }else if(currentView === "VipInfo"){
            navigate("/Login?vista=VIP");
        }

    };

    return (
        <div>
        <AppBar component="nav" position="fixed" sx={{p:0}} color="inherit">
            <Container maxWidth="xl">
            <Toolbar disableGutters>
            <Link href="/">
                <Box sx={{ display: "flex", alignItems: "Center" }}>
                <LogoIcon />
                </Box>
            </Link>
                
                <Box  sx={{ flexGrow: 1 }}/>
               {ShowButton && 
                        <ThemeColor>
                            {isMobile ?
                            <IconButton aria-label="Login" onClick={handleLogin}>
                                <LoginIcon color='blue' fontSize='large'/>
                            </IconButton>
                            :
                             <Button   color="blue" style={{ color: 'white' }} variant="contained" onClick={handleLogin}>
                                Iniciar Sesión
                             </Button>
                            }
                        </ThemeColor>
                       
                    
                }
            </Toolbar>
            </Container>
        </AppBar>
        </div>
        

    );

};
export default AppBarContent;