import React, {useState} from "react";
import UsuarioMessage from "./vistaVIP-components/UsuarioMessage";
import FullLayout from "../../layouts/FullLayout/FullLayout";
import Spinner from "../Spinner/Spinner";
import  MessageCard from "./vistaVIP-components/MessageCard";

const PaginaInicio =() => {
    const [showSpinner, setShowSpinner] = useState(false);
   

    handleShowSpinnerVip = () =>{
        setShowSpinner(true);
    };
    handleShowSpinnerStopVip = () =>{
        setShowSpinner(false);
    };
   
    return (
        <div>
            <FullLayout>
                {!showSpinner && <UsuarioMessage/>}
                {showSpinner &&<Spinner/> }
           
                {!showSpinner && <MessageCard/>}
            </FullLayout>
       
        
        </div>
        
    );
}
export default PaginaInicio;
export function handleShowSpinnerVip() { };
export function handleShowSpinnerStopVip() { };
