import { Navigate } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute.js";
import {ProtectedRouteAdmin} from "./ProtectedRoute.js";
import { ProtectedRouteEmail } from "./ProtectedRoute.js";
import { ProtectedRoutePassword } from "./ProtectedRoute.js";

/* Lazy */
import Login from "../views/Login/Login";
import CrearCuenta from "../views/CrearCuenta/CrearCuenta";
import CrearPassword from "../views/CrearPassword/CrearPassword";
import RecuperaPassword from "../views/RecuperaPassword/RecuperaPassword";
import CreaNuevoPassword from "../views/RecuperaPassword/CreaNuevoPasword";
import VerificarEmail from "../views/VerificarEmail/Email";
import VistaTunuevacasa from "../views/VistaTunuevacasa/VistaTunuevacasa";
import VistaAMIC from "../views/VistaAMIC/VistaAMIC";
import VistaVIP from "../views/VistaVIP/VistaVIP";
import VistaInicial from "../views/VistaInicial/VistaInicial";
import RenoVarte from "../views/VistaInicial/VistaInicial-components/Vista-Propuestas/PropuestaRenovarte";
import Tunuevacasa from "../views/VistaInicial/VistaInicial-components/Vista-Propuestas/PropuestaTunuevaCasa";
import AMIC from "../views/VistaInicial/VistaInicial-components/Vista-Propuestas/PropuestaAMIC";
import VIP from "../views/VistaInicial/VistaInicial-components/Vista-Propuestas/PropuestaVip";
import Perfil from '../views/Perfil/Perfil';
import Admin from '../views/Admin/Admin';
// const FullLayout = lazy(() => import("../layouts/FullLayout/FullLayout.js"));

/* Router */
const ThemeRoutes = [
  { path: "/", exact: true, element: <VistaInicial/> },
  { path: "/VistaInicial", exact: true, element: <VistaInicial/> },
  { path: "/Renovarte", exact: true, element: <RenoVarte/> },
  { path: "/Tunueva.casaInfo", exact: true, element: <Tunuevacasa/> },
  { path: "/AmicInfo", exact: true, element: <AMIC/> },
  { path: "/VipInfo", exact: true, element: <VIP/> },
  { path: "/Login", exact: true, element: <Login/> },
  { path: "/crearCuenta", exact: true, element: <CrearCuenta/> },
  { path: "/verificarEmail", exact: true, element: <ProtectedRouteEmail><VerificarEmail/></ProtectedRouteEmail> },
  { path: "/crearPassword", exact: true, element: <ProtectedRoutePassword><CrearPassword/></ProtectedRoutePassword> },
  {path:  "/RecuperaPassword", exact:true, element:<RecuperaPassword/> },
  { path: "/creaNuevoPassword", exact: true, element: <ProtectedRoutePassword><CreaNuevoPassword/></ProtectedRoutePassword> },
  { path: "/Tunueva.casa", exact: true, element:<ProtectedRoute roles={{ 1: true, 2: true, 3:true }}><VistaTunuevacasa/></ProtectedRoute>  },
  { path: "/AMIC", exact: true, element:<ProtectedRoute roles={{ 2: true, 3: true }}><VistaAMIC/></ProtectedRoute>  },
  { path: "/VIP", exact: true, element:<ProtectedRoute roles={{ 3: true }}><VistaVIP/></ProtectedRoute> },
  { path: "/Tunueva.casa/perfil", exact: true, element:<ProtectedRoute roles={{ 1: true, 2: true, 3:true }} ><Perfil/></ProtectedRoute> },
  { path: "/AMIC/perfil", exact: true, element:<ProtectedRoute roles={{ 2: true, 3: true }}><Perfil/></ProtectedRoute> },
  { path: "/VIP/perfil", exact: true, element:<ProtectedRoute roles={{ 3: true }}><Perfil/></ProtectedRoute> },
  { path: "/Admin", exact: true, element:<ProtectedRouteAdmin><Admin/></ProtectedRouteAdmin>  },
  // {
  //   path: "/",
  //   element:  <FullLayout/>,
  //   children: [
  //   ],
  // },
  { path: "*", element: <Navigate to="VistaInicial"/> },
];

export default ThemeRoutes;