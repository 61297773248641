import React from "react";
import AppBarContent from "../AppBar";
import FooterContent from "../Footer/Footer";
import {Grid, Card, Stack, Container, Box, Typography, Button, useMediaQuery} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ThemeColor from '../../../../components/Theme/Theme';
import VipImg from '../../../../assets/images/card-img/RenovArteVIP.png';
import ImagenVIP1 from '../../../../assets/images/card-img/ImagenVIP1.png';
import ImagenVIP5 from '../../../../assets/images/card-img/ImagenVIP5.png';
import ScrollAnimation from 'react-animate-on-scroll';

const PropuestaVip = () =>{

    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width:600px)');
    const handleLogin =() =>{
        navigate("/Login?vista=VIP");

    };

    return(
        <div>
        {/*BLOQUE 1 */}
         <header style={{ position: 'relative', width: '100%' }}>
                <link
                    rel="stylesheet"
                    href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"
                />
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css"></link>
                <AppBarContent />

                <div style={{
                    position: 'relative',
                    width: '100%',
                    height: '100%'
                }}>

                    {isMobile ? 
                     <Box sx={{ bgcolor: '#FC292B', height:'100vh' }} />
                     :
                        <img
                        src={VipImg}
                        alt='Vipimg'
                        width='100%'
                        className="w-full h-full object-cover"
                    />
                    }
                    <div style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)', 
                        zIndex: 1 
                    }} />

                    <div style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 2
                    }}>
                        <Typography className='animate__animated animate__fadeInDown animate__delay-1s' variant="h1" gutterBottom sx={{mb:4, fontWeight:"bold", textAlign:"center"}} color='white' >
                            UNA EXCLUSIVA PLATAFORMA PARA LÍDERES INNOVADORES
                        </Typography>
                        <Container maxWidth='lg'>
                            <Box>
                                <Typography className='animate__animated animate__fadeInUp animate__delay-1s' variant="body1"  sx={{textAlign:"center"}} color='whitesmoke' >
                                En RenovArte, hemos creado un espacio único y exclusivo: la Zona VIP. Este área está diseñada 
                                para aquellos que no solo reconocen el potencial de la innovación en el ecosistema inmobiliario 
                                y de la construcción, sino que también están dispuestos a ser parte activa de esta transformación. 
                                El acceso a la Zona VIP es exclusivo para quienes desean contribuir activamente con 
                                comentarios, ideas y propuestas que resuenen con nuestra visión de un mercado más sostenible y 
                                eficiente
                                </Typography>
                        </Box>
                        </Container>
                    </div>
                </div>

        </header>
        <Stack
           direction="column"
           justifyContent="center"
           alignItems="center"
           sx={{
                marginTop:"100px"
               }}
        
        >
                  
              {/*BLOQUE 2 */}
              <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' delay={450}>
              <Typography variant="h1" gutterBottom sx={{mb:4, fontWeight:"bold", textAlign:"center"}} >
                  UNA COMUNIDAD DE PENSADORES AVANZADOS
              </Typography>
              <Container sx={{mb:4}} >
                  <Typography  variant="subtitle1" gutterBottom sx={{textAlign:"justify", marginRight:"5%", marginLeft:"5%"}} >
                    La admisión a nuestra Zona VIP se basa en el mérito de las ideas y el compromiso con la 
                    sostenibilidad y la innovación. Es un lugar para aquellos que ven más allá del status quo y están 
                    listos para discutir, colaborar y formar alianzas estratégicas que impulsen una transformación 
                    real y significativa en el sector. Aquí, tu voz y tus ideas tienen el poder de moldear el futuro de la 
                    construcción y el inmobiliario
                  </Typography>
              </Container>
              </ScrollAnimation>
              <ScrollAnimation animateIn='flipInY' animateOut='flipOutY' delay={500}>
              <Box sx={{alignContent:"center", mb:17}}>
                                <ThemeColor>
                                   <Button variant="contained" style={{ color: 'white' }}  color="blue" onClick={handleLogin}>
                                            Iniciar Sesión
                                    </Button> 
                                </ThemeColor>
                </Box>
             </ScrollAnimation>
              {/*BLOQUE 3 */}
              <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' delay={650}>
              <Typography variant="h1" gutterBottom sx={{mb:5, fontWeight:"bold", textAlign:"center"}} >
                    TRANSFORMACIÓN IMPULSADA POR LA COLABORACIÓN
                </Typography>

                  <Grid 
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{mb:17}}
                  spacing={2}
              >
                  <ScrollAnimation animateIn='flipInY' animateOut='flipOutY' delay={760}>
                  <Grid item 
                      sx={{
                              display: "flex",
                              alignItems: "stretch",
                          }}>
                      <Card 
                      variant="outlined"
                      sx={{
                          maxWidth:450,
                          p: 0,
                      
                      }}
                      >
                      <img src={ImagenVIP1}  alt="nevacasa.img" height="100%" width="100%"/>
                      </Card>

                  </Grid>
                  </ScrollAnimation>
                  
                  <Grid item >
                      <Container maxWidth="sm"> 
                      <Box>
                          <Typography  variant="subtitle1" gutterBottom sx={{textAlign:"justify"}} >
                            En la Zona VIP, cada comentario y cada conversación tiene el potencial de desencadenar 
                            innovaciones que redefinirán la industria. Esta es una de las muchas iniciativas que hemos 
                            lanzado en colaboración con líderes de la industria inmobiliaria y de la construcción, enfocadas 
                            en crear oportunidades de negocio que no solo sean viables económicamente, sino también 
                            sostenibles para nuestro planeta.
                          </Typography>
                      </Box>
                      </Container>
                  </Grid>

              </Grid>
              </ScrollAnimation>

                {/*BLOQUE 4 */}
                <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' delay={650}>
                <Typography variant="h1" gutterBottom sx={{mb:5, fontWeight:"bold", textAlign:"center"}} >
                    CREE VALOR SOSTENIBLE PARA LA SOCIEDAD
                </Typography>
                <Grid 
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{mb:17}}
                  spacing={2}
              >
                  
                  <Grid item >
                      <Container maxWidth="sm"> 
                      <Box>
                          <Typography  variant="subtitle1" gutterBottom sx={{textAlign:"justify"}} >
                            Su participación en la Zona VIP de RenovArte significa un compromiso con el cambio, la 
                            innovación y la profesionalidad. Es una oportunidad para influir en cómo las propiedades son 
                            concebidas, construidas y renovadas, impactando positivamente en la comunidad y en la 
                            sociedad en general. Estamos construyendo un legado de prácticas éticas y sostenibles que 
                            fomentan un entorno mejor y más responsable.
                          </Typography>
                      </Box>
                      </Container>
                  </Grid>
                    
                  <ScrollAnimation animateIn='flipInY' animateOut='flipOutY' delay={760}>
                  <Grid item 
                      sx={{
                              display: "flex",
                              alignItems: "stretch",
                          }}>
                      <Card 
                      variant="outlined"
                      sx={{
                          maxWidth:450,
                          p: 0,
                      
                      }}
                      >
                      <img src={ImagenVIP5}  alt="nevacasa.img" height="100%" width="100%"/>
                      </Card>

                  </Grid>
                  </ScrollAnimation>

              </Grid>
              </ScrollAnimation>
                
                {/*BLOQUE 5 */}
                <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' delay={800}>
              <Container  sx={{mb:17}}>
                  <Box  display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{ bgcolor: '#F6641F', marginRight:"5%", marginLeft:"5%", borderRadius:5, }}>
                    <ScrollAnimation animateIn='bounceInRight' mateOut='bounceOutLeft' delay={860}>
                    <Typography variant="h1" gutterBottom sx={{mb:4, fontWeight:"bold", color:"white", paddingTop:"10%", textAlign:"center"}} >
                            ÚNETE A NOSOTROS
                    </Typography>

                    <Typography  variant="subtitle1" gutterBottom sx={{textAlign:"center", color:"white", marginRight:"5%", marginLeft:"5%", mb:4}} >
                        Si estás listo para hacer más que observar el cambio, si estás listo para liderarlo y dar forma a un 
                        futuro sostenible, te invitamos a compartir tu visión en la Zona VIP de RenovArte. Explora cómo 
                        tu perspectiva puede convertirse en parte de nuestra misión colectiva para revolucionar la 
                        industria.
                    </Typography>
                    </ScrollAnimation>

                    <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' delay={910}>
                    <Box sx={{paddingBottom:"10%",}}>
                                <ThemeColor>
                                   <Button variant="contained"  style={{ color: '#1E386E', fontWeight:"bold", }}  color="white" onClick={handleLogin}>
                                            Iniciar Sesión
                                    </Button> 
                                </ThemeColor>
                    </Box>
                    </ScrollAnimation>
                  </Box>

              </Container>
              </ScrollAnimation>
        </Stack>
        <FooterContent/>
  </div>

    );

};
export default PropuestaVip;