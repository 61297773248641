import React from "react";
import { Grid, Avatar, Box, Card, useMediaQuery } from "@mui/material";
import user1 from "../../../assets/images/users/3.jpg";
import Modelo from "../../../Model/FuncionAlmacenar";
import DatosVacio from "./DatoVacio";
import ImageRespuesta from "./ImagenRespuesta";
import DeleteButton from "../../../components/DeleteComponent/ButtonDeleteComponent";
import ResponsiveText from "../../../components/ResponsiveText/ResposiveText";

const UsuarioMessage = () => {
    const datos = Modelo.recuperarTodosLosRegistros();
    const userRole = JSON.parse(sessionStorage.getItem('userRole'));
    const isMobile = useMediaQuery('(max-width:600px)');
    //Componente para indicar que aún no hay nada creado
    function NoHayDatos() {
        if (datos.length <= 0) {
            return (
                <DatosVacio />
            )
        }
    }

    return (
        <div>
            <NoHayDatos />
            {isMobile && (userRole === 1 || userRole === 2) ?
            <Box height={50}/>
            : null
            }

            {datos.map((item, index) => (
                <Grid key={index} container sx={{ margin: 'auto' }} direction="row" alignItems="center" rowSpacing={1} >

                    <Box sx={{ flexGrow: 1, overflow: 'hidden', px: 3 }}>
                        {/*--- Avatar y mensaje---- */}

                        <Grid container wrap="nowrap" spacing={1}>
                            <Grid item >
                                <Avatar sx={{ width: 40, height: 40 }} src={user1}></Avatar>
                            </Grid>

                            <Grid item >
                                <ResponsiveText text={userRole ===1 || userRole ===2 ? item.options : item.meta.prompt}/>
                            </Grid>

                            <Grid item >
                                <DeleteButton idAEliminar={item.id}></DeleteButton>
                            </Grid>
                        </Grid>

                        <Box height={30} />


                        <Grid container>
                            {/*--- Imagen inincial---- */}
                            <Grid
                                item
                                xs={12}
                                lg={5}
                                sx={{
                                    display: "flex",
                                    alignItems: "stretch",
                                }}
                            >
                                <Card
                                    variant="outlined"
                                    sx={{
                                        p: 0,
                                        width: "100%"
                                    }}
                                >
                                    <img src={item.meta.init_image} key={item.meta.init_image} alt="img" height="100%" width="100%" />

                                </Card>
                            </Grid>
                            {/*--- Imagen Resultado ---- */}
                            <Grid
                                item
                                xs={12}
                                lg={5}
                                sx={{
                                    display: "flex",
                                    alignItems: "stretch"
                                }}
                            >
                                <Card
                                    variant="outlined"
                                    sx={{
                                        p: 0,
                                        width: "100%",
                                    }}
                                >
                                    
                                    {item.status === "success" ? (
                                        <ImageRespuesta images={item.output} />
                                    ) : ( 
                                        <ImageRespuesta images={item.future_links} />
                                    )}
                                    
                                    


                                </Card>
                            </Grid>
                        </Grid>

                        <Box height={30} />

                    </Box>
                </Grid>
            ))}
        </div>
    );
}
export default UsuarioMessage;