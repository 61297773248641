import Datos from "../Model/FuncionAlmacenar";
import {handleShowSpinnerStop} from "../views/VistaTunuevacasa/VistaTunuevacasa";
import { handleShowSpinnerStopArqui } from "../views/VistaAMIC/VistaAMIC";
import {handleShowSpinnerStopVip} from "../views/VistaVIP/VistaVIP";

export async function GetResponseAPI(init_image, prompt, negative_prompt, options){
    const API_KEY =process.env.REACT_APP_API_KEY;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
    "key": API_KEY,
    "init_image" : init_image,
    "prompt" : prompt,
    "negative_prompt": negative_prompt,
    "steps" : 50,
    "guidance_scale": 8,
    "samples" : 4,
    "enhance_prompt": "yes",
    "multi_lingual": "yes",
    });

    var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
    };
  try{
      const response = await fetch("https://modelslab.com/api/v5/interior", requestOptions);
     
      if(!response.ok){
          throw new Error(`API request failed with status': ${response.status}`);
      }
      const result = await response.json();
      if(result.status === "success"){
        result.options = options;
        Datos.almacerEnCacheModelo(result, options);
        return {consult: true};
        //console.log(result);
    }else if(result.status === "processing"){
          Datos.almacerEnCacheModelo(result, options);
          await FutureLinks(result.fetch_result);
          return {consult: true};
          //console.log(result);
    }else if(result.status === "error"){
        alert('Esta imagen desafortunadamente no podemos identificarla, prueba con otra o intentalo de nuevo');
        return {consult:false};
      }
   
  }catch(error){
      alert('Esta imagen desafortunadamente no podemos identificarla, prueba con otra o intentalo de nuevo.');
      console.log("Error: ", error);
  }finally{
        handleShowSpinnerStop();
        handleShowSpinnerStopArqui();
        handleShowSpinnerStopVip();
  }
    
};

async function FutureLinks(edpoitn, maxRetries = 13, delay = 12000) {
  const API_KEY = process.env.REACT_APP_API_KEY;
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
      "key": API_KEY,
  });

  var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
  };
  for(let retries = 0; retries < maxRetries; retries++){
      
          const response = await fetch(edpoitn, requestOptions);
          const result = await response.json();

          if (result.status === "success") {
              return result;
          } else if(result.status === "processing"){
              //console.log(`Still processing. Attempt ${retries + 1}/${maxRetries}. Retrying in ${delay / 1000} seconds...`);
              if (retries < maxRetries - 1) {
                await new Promise(resolve => setTimeout(resolve, delay));
            }
          }else{
              throw new Error(`API returned unexpected status: ${result.status}`)
          }
         
      }
      
      throw new Error("Max retries reached. The image generation is taking too long");
  }

