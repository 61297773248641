import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Box, Grid, ImageList, ImageListItem, ImageListItemBar, Stack, Typography, useMediaQuery  } from '@mui/material';
import CardAmic from './VistaInicial-components/CardAmic';
import CardTunuevacasa from './VistaInicial-components/CardTunuevacasa';
import CardNuevoAliado from './VistaInicial-components/CardNuevoAliado';
import CardVIP from './VistaInicial-components/CardVIP';
import AppBarContent from './VistaInicial-components/AppBar';
import FooterComponent from './VistaInicial-components/Footer/Footer';
import img from '../../assets/images/card-img/ImagenAMIC3.png';
import ScrollAnimation from 'react-animate-on-scroll';
const images = require.context('../../assets/images/renovImg', false, /\.(webp)$/);

const itemData = [
    {
      img:images(`./renov1.webp`),
      title: 'Sala de Estar',
      author: 'Sala de Estar',
    },
    {
      img:images('./renov2.webp'),
      title: 'Cocina',
      author: 'Cocina',
    },
    
    {
      img:images('./renov3.webp'),
      title: 'Fachada',
      author: 'Fachada',
    },
    {
      img:images('./renov4.webp'),
      title: 'Dormitorio con estilo Halloween',
      author: 'Darren Richardson',
    },
    {
      img: images('./renov5.webp'),
      title: 'Habitación con Enfasis en el Diseño',
      author: 'Habitación con Enfasis en el Diseño',
    },
    {
        img: images('./renov6.webp'),
        title: 'Sala de Reuniones con Estilo Navideño',
        author: 'Sala de Reuniones con Estilo Navideño',
    },
    {
      img: images('./renov7.webp'),
      title: 'Fachada Minimalista',
      author: 'Fachada Minimalista',
    },
    {
        img:images('./renov8.webp'),
        title: 'Sala de Reuniones',
        author: 'Sala de Reuniones',
    },
    {
      img:images('./renov9.webp'),
      title: 'Dormitorio Con Enfasis en el Lujo',
      author: 'Dormitorio Con Enfasis en el Lujo',
    },
    {
        img:images('./renov10.webp'),
        title: 'Exterior con Caminos decorativos',
        author: 'Exterior con Caminos decorativos',
    },
    
    
  ];
const VistaInicial = () => {
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width:600px)');
    const handleNavigate = () => {
        navigate("/Renovarte")
    };

    return (
        <div>
            <header style={{ position: 'relative', width: '100%' }}>
                <link
                    rel="stylesheet"
                    href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"
                />
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css"></link>
                <AppBarContent />

                <div style={{
                    position: 'relative',
                    width: '100%',
                    height: '100%'
                }}>

                    {isMobile ? 
                     <Box sx={{ bgcolor: '#1E386E', height:'100vh' }} />
                     :
                    <img
                        src={img}
                        alt='img'
                        width='100%'
                        className="w-full h-full object-cover"
                    />
                    }
                    <div style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Fondo negro semi-transparente
                        zIndex: 1 // Asegura que esté sobre la imagen pero debajo del texto
                    }} />

                    <div style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 2
                    }}>
                        <Typography className='animate__animated animate__fadeInDown animate__delay-1s' sx={{ textAlign: "center", fontWeight: "bold", mb: 0 }} variant="h1" color="white" gutterBottom >
                           <div><h4>DESCUBRE EL POTENCIAL OCULTO DE TU ESPACIO CON <span style={{color:'#1E386E', fontSize:'130%'}}>Renov</span><span style={{color:'#FC292B',fontSize:'130%'}}>Arte</span></h4></div>
                        </Typography>
                        <Typography className='animate__animated animate__fadeInUp animate__delay-1s' sx={{ textAlign: "center", mb: 3, color: 'white' }} variant="h3" gutterBottom  >
                            Transforma Tu Hogar o Departamento en Segundos
                        </Typography>
                        <Button className='animate__animated animate__fadeIn animate__delay-2s' onClick={handleNavigate} variant="contained" color='secondary' size="large">
                            ¿ Qué es RenovArte ?
                        </Button>
                    </div>
                </div>

            </header>
            <section>   
            <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                sx={{
                    marginTop: "100px",
                    mb: 10
                }}
            >
                <section id='section1'>
                <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' delay={600}>
                <Typography variant="h1" gutterBottom sx={{ fontWeight:"bold", textAlign:"center"}} color='#1E386E' >
                   <h4>IMÁGENES GENERADAS POR <span style={{fontSize:'115%'}}>Renov</span><span style={{color:'#FC292B', fontSize:'115%'}}>Arte</span></h4>
                </Typography>
                </ScrollAnimation>
                </section>
                <ScrollAnimation
                 animateIn='fadeIn'
                 animateOut='fadeOut'
                 delay={650}>
                <Box sx={{ width: isMobile ? 340 : 1000, height: 380, overflowY: 'scroll', mb:10}}>
                <ImageList variant="masonry" cols={isMobile ? 2:3} gap={8}>
                    {itemData.map((item) => (
                    <ImageListItem key={item.img}>
                        <img
                        srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                        src={`${item.img}?w=248&fit=crop&auto=format`}
                        alt={item.title}
                        loading="lazy"
                        />
                        <ImageListItemBar position="below" title={item.title} />
                    </ImageListItem>
                    ))}
                </ImageList>
                </Box>
                </ScrollAnimation>
               

                <section>          
                <ScrollAnimation
                 animateIn='fadeIn'
                 animateOut='fadeOut'
                 delay={700}>
                
                <Typography className='animate__animated animate__fadeInDown animate__delay-1s' sx={{ textAlign: "center", fontWeight: "bold", mb: 4 }} variant="h1" color="#1E386E" gutterBottom >
                            CONOCE A NUESTROS ALIADOS
                </Typography>
                </ScrollAnimation>

                <ScrollAnimation 
                    animateIn='fadeIn'
                    animateOut='fadeOut'
                    delay={750}>
                    <div>
                        <Grid
                            container
                            direction="row"
                            rowSpacing={1}
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                                margin: "auto",
                                display: "flex",
                                alignItems: "stretch",
                                mb: 10
                            }}
                        //style={{ minHeight: '100vh' }} 
                        >
                            <Grid item >
                                <CardTunuevacasa />
                            </Grid>

                            <Grid item >
                                <CardAmic />
                            </Grid>

                            <Grid item>
                                <CardNuevoAliado />
                            </Grid>

                            <Grid item>
                                <CardVIP />
                            </Grid>
                        </Grid>
                    </div>
                </ScrollAnimation>
                </section> 
            </Stack>
            </section> 
            <FooterComponent />
        </div>

    );

}
export default VistaInicial;