import TNCKLOGO from "../assets/images/LogoWhitemark/TNCK.png";
//import TNCCLOGO from "../assets/images/LogoWhitemark/TNCC.png";
//import documnetPath from "../assets/doc/RenovArte.txt";
import DocInfo from "../Model/Docinfo";
export async function MaxResolution(imgURL){
    const API_KEY =process.env.REACT_APP_API_KEY;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
    "key": API_KEY,
    "url": imgURL,
    "scale": 3,
    "webhook": null,
    "face_enhance": false
    });

    var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
    };
    try{
        const response = await fetch("https://stablediffusionapi.com/api/v5/super_resolution", requestOptions);
        if(!response.ok){
            throw new Error(`API request failed with status': ${response.status}`);
        }
        const result = await response.json();
        
        if(result.status === "error"){

            return {style:"error", message:"Ocurrio un error"};
        }
        
        if(result.status === 'success'){

            const {style, message} = await DownloadImage(result.output);

            return {style:style, message:message};
            
        }else if(result.status === 'processing'){

            const {imgUrl, message, maxresolution} = await FutureLinks(result.fetch_result);

            if(maxresolution){

              const {style, message} = await DownloadImage(imgUrl.output);
              return {style:style, message:message};
            }

            return {style:'error', messageErr:message };
            
        }
       
          
    }catch(err){
        return "Ocurrion un error";
    }


};

async function FutureLinks(edpoitn, maxRetries = 13, delay = 12000) {
    const API_KEY = process.env.REACT_APP_API_KEY;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
  
    var raw = JSON.stringify({
        "key": API_KEY,
    });
  
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };
    for(let retries = 0; retries < maxRetries; retries++){
        
            const response = await fetch(edpoitn, requestOptions);
            const result = await response.json();
  
            if (result.status === "success") {
                //console.log("Imagen con respuesta en success", result);
                return {imgUrl:result, message:'', maxresolution:true};
            } else if(result.status === "processing"){
              //console.log('result:', result);
                //console.log(`Still processing. Attempt ${retries + 1}/${maxRetries}. Retrying in ${delay / 1000} seconds...`);
                if (retries < maxRetries - 1) {
                  await new Promise(resolve => setTimeout(resolve, delay));
                }else{
                  return{ imgUrl: '', message:'No se puedo obtener la iamgen con alta resolución', maxresolution:false};
                }
                //console.log(result);
            }else{
                throw new Error(`API returned unexpected status: ${result.status}`)
            }
           
        }
        
        throw new Error("Max retries reached. The image generation is taking too long");
    }

async function DownloadImage(imgURL, maxRetries = 10, delay = 1000){
  //obtener la fecha y hora actual 
  const now = new Date();
  const timesTamp =  now.getFullYear()+'-'+
  ('0' +(now.getMonth() +1 )).slice(-2) + '-'+
  ('0' + now.getDate()).slice(-2) + '_'+
  ('0' + now.getHours()).slice(-2) + '-' +
  ('0' + now.getMinutes()).slice(-2);
//Nombre de imagen y archivo txt
  const fileName = `RenovArte_${timesTamp}`;
  const docName = `RenovArte_${timesTamp}`;

  for (let retries = 0; retries < maxRetries; retries++) {

    try {
      // Intentamos hacer el fetch con el enlace de la imagen
      const response = await fetch(imgURL);      
      // Verificamos si la respuesta es exitosa
      if (response.ok) {
        // Convertimos la respuesta a un blob
        const blob = await response.blob();

        // Creamos un objeto URL a partir del blob
        const imgURLObject = URL.createObjectURL(blob);

        // Creamos un elemento de imagen
        const img = new Image();
        img.src = imgURLObject;

        // Esperamos a que la imagen se cargue
        await new Promise((resolve) => {
          img.onload = resolve;
        });

        // Creamos un elemento canvas
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        // Establecemos el tamaño del canvas (igual al tamaño de la imagen original)
        canvas.width = img.width;
        canvas.height = img.height;

        // Dibujamos la imagen original en el canvas
        ctx.drawImage(img, 0, 0);

        // Función para cargar una imagen
        async function loadImage(url) {
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error(`Failed to fetch image: ${url}`);
          }
          const blob = await response.blob();
          const imageUrl = URL.createObjectURL(blob);
          return new Promise((resolve) => {
            const img = new Image();
            img.onload = () => resolve(img);
            img.src = imageUrl;
          });
        }

        // Cargamos las tres imágenes de marca de agua
        const [centerLogo,] = await Promise.all([
          loadImage(TNCKLOGO), 
        ]);

        // Función para dibujar una marca de agua
        function drawWatermark(image, x, y, width, height, opacity = 0.5) {
          ctx.globalAlpha = opacity;
          ctx.drawImage(image, x, y, width, height);
          ctx.globalAlpha = 1.0;
        }

        // Marca de agua central inferior
        const centerWidth = canvas.width * 0.5; //50% del ancho de la imagen
        const centerHeight = (centerLogo.height / centerLogo.width) * centerWidth; //Mantiene la proporción
        const centerX = (canvas.width - centerWidth) / 2; //Centrado horizontal
        const centerY = canvas.height - centerHeight - 10; // 10px de margen inferior
        drawWatermark(centerLogo, centerX, centerY, centerWidth, centerHeight);

        // Marca de agua inferior izquierda
        /*const sideWidth = canvas.width * 0.1;  // 10% del ancho de la imagen 
        const sideHeight = (leftLogo.height / leftLogo.width) * sideWidth; //Mantiene la posición
        const leftX = canvas.width * 0.05; // left: 0.5%
        const leftY = canvas.height * 0.2 - sideHeight; //top 20 %
        drawWatermark(leftLogo, leftX, leftY, sideWidth, sideHeight);*/

        // Marca de agua inferior derecha
        /*const rightX = canvas.width * 0.95 - sideWidth;//right:95%
        const rightY = canvas.height * 0.2 - sideHeight;//top: 20% 
        drawWatermark(rightLogo, rightX, rightY, sideWidth, sideHeight);*/

        // Creación de un nuevo blob a partir del canvas
        const newBlob = await new Promise((resolve) => {
          canvas.toBlob(resolve, "image/png");
        });


        // Verificar si se creó el blob
        if (!newBlob) {
          throw new Error("Error al crear el blob de la imagen.");
        }
        // Creamos un enlace temporal para la descarga
        const link = document.createElement("a");
        link.href = URL.createObjectURL(newBlob);
        link.download = fileName;

        // Simulamos un clic en el enlace para iniciar la descarga
        link.click();
        
        // Limpiamos el objeto URL creado
        URL.revokeObjectURL(link.href);

        // Función para formatear el tamaño del archivo
        function formatFileSize(bytes) {
          if (bytes < 1024) return bytes + ' bytes';
          else if (bytes < 1048576) return (bytes / 1024).toFixed(2) + ' KB';
          else return (bytes / 1048576).toFixed(2) + ' MB';
        };

        const size = formatFileSize(newBlob.size);
        //Propiedades de la imagen
        const propiedades = {
          width: img.width, 
          height: img.height, 
          aspectRatio:(img.width / img.height).toFixed(2),
          size: size,
          name: fileName
        };
        const data = DocInfo(propiedades)
        createLocalFileDownloadLink(data, docName);
        // Mostramos el mensaje de éxito
            return {style:"success", message:"La imagen se ha descargado correctamente."};

        }else if(response.status === 404){
                //console.log(`Still processing. Attempt ${retries + 1}/${maxRetries}. Retrying in ${delay / 1000} seconds...`);
                if (retries < maxRetries - 1) {
                    await new Promise(resolve => setTimeout(resolve, delay));
                }
            }else{
                throw new Error(`API returned unexpected status: ${response.status}`);
      }
            
            
    } catch (error) {
            // Capturamos cualquier error que ocurra durante el proceso
           return {style:"error", message:"No se pudo descargar la imagen. Por favor, inténtelo de nuevo más tarde"};
            
          }

  }
  throw new Error("Max retries reached.");

};

// Función para crear enlace de descarga archivo txt
function createLocalFileDownloadLink(data, fileName) {
  const link = document.createElement('a');
  const archivo = new Blob([data], {type:'text/plain'});
  link.href = URL.createObjectURL(archivo);
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}