import React, {useState, useEffect} from "react";
import {Skeleton, Box, Button, Typography } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import TNCKLOGO from "../../assets/images/LogoWhitemark/TNCK.png";

function ImageLoader({ src, alt, width, height, onClick, hasLogoWhitemark}) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    //Función para poder cargar la imagen generada
    const loadImage = () => {
        setLoading(true);
        setError(false);
        const img = new Image();
        img.src = src;
        img.onload = () => setLoading(false);
        img.onerror = () => {
            setTimeout(() =>{
                setLoading(false);
            },2000);
            setError(true);
        };
    }

    //Se ejecuta cuando el estado se vuleve a renderizar
    useEffect(() => {
        loadImage();
    }, [src]);


    //Función para poder recargar la imagen 
    const hanleReload = () => {
        loadImage();
    };

    if (loading) {
        return <Skeleton variant="rectangular" with={width} height={height} animation="wave" />
    }
    if (error) {
        return (
            <Box 
            width={width} 
            height={height} 
            display="flex" 
            alignItems="center" 
            justifyContent="center" 
            bgcolor="grey.300"
            >
                <Box mb={1} >
                    <Typography 
                    variant="subtitle2"
                    >
                        Error al cargar la imagen
                    </Typography>  
                </Box>
                <Button 
                variant="contained" 
                startIcon={<RefreshIcon />} 
                onClick={hanleReload}>
                    Volver a cargar
                </Button>
            </Box>
        );
    }
    return (
        <Box sx={{ position: 'relative', display: 'inline-block', width: width, height: height }}>
            {/* Main Image */}
            <img
                src={src}
                alt={alt}
                width={width}
                height={height}
                onClick={onClick}
                onContextMenu={(e) => e.preventDefault()}
                loading="lazy"
                style={{ display: 'block', width: '100%', height: '100%' }}
            />
    
            {/* Logo Whitemark */}
            {hasLogoWhitemark && (
                <div>
                     {/* Logo en la parte inferior */}
                <Box
                    // component="img"
                    // src="../../../../assets/images/logo-renovarte.png"
                    alt="Logo Whitemark"
                    sx={{
                        position: 'absolute',
                        top: '90%',
                        left: '60%',
                        transform: 'translate(-50%, -50%)',
                        opacity: 0.5,
                        width: '80%',
                        height: 'auto',
                        pointerEvents: 'none',
                    }}
                >
                    <img alt="Logo" src={TNCKLOGO} width="80%"/>
                </Box>

                </div>
            )}
        </Box>
    )
};
export default ImageLoader;