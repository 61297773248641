import React from 'react';
import CardFormAutocomplete from '../VistaTunuevacasa/vistaTunuevacasa-components/Autocomplet-Components/CardFormAutocomplete';
import FormPerfil from './Perfil-Components/Form-Perfil';
import FullLayout from "../../layouts/FullLayout/FullLayout";

const Perfil = () =>{
    return (
        <div>
            <FullLayout>
            <CardFormAutocomplete title="Mi cuenta">
                <FormPerfil/>
            </CardFormAutocomplete>
            </FullLayout>
            

        </div>

    );

};
export default Perfil;