import React, {useState,useEffect} from "react";
import {
  Typography,
  Box,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton
} from "@mui/material";
import logo from '../../../assets/images/logo-renovarte.png';
import autoTable from 'jspdf-autotable'
import jsPDf from 'jspdf';
import DeleteIcon from  '@mui/icons-material/DeleteOutline';
import EditIcon from  '@mui/icons-material/EditOutlined';
import {ConsultarUsuarios, EliminarUsuario} from '../../../API/Admin';
import { updateRowState } from "../Admin";
import Alerta from "../../../components/AlertaComponent/Alerta";
import Spinner from "../../Spinner/Spinner";
import DialogDeleteUser from "./DialogDeleteUser";
import DialogFormUpdateUser from "./DialogFormUpdateUser";
import AnyUserIcon from "@mui/icons-material/ClearAllOutlined";

const ExTable = ({showRow}) => {
  const [isLoading, setIsloading] = useState(true);
  const [openAlert, setOpenAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [style, setStyle] = useState("");
  const [openDialoguser, setOpenDialogUser] = useState(false);
  const [openDialogEditUser, setOpenDialogEditUser] = useState(false);
  const [usuarios, setUsuarios] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phonenumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [userType, setUserType] = useState(null);
  const [rol, setRol] = useState(null);
  const [session, setSession] = useState(null);

  
  useEffect(()=> {
    handleconsultarUsuarios();
  },[]);

  handleconsultarUsuarios = async() =>{
    try{
      const {usuarios,  success, messageErr, styleErr} = await ConsultarUsuarios();
      if(success){
        MostrarAlerta();
        setMessage(messageErr);
        setStyle(styleErr);
        setIsloading(true);
        setShowTable(true);
        setTimeout(()  =>{
          setIsloading(false);
        },1000)
        return;
      }
      if(!success){
        setIsloading(true);
        setTimeout(()  =>{
          setIsloading(false);
          setShowTable(false);
        },1000)
        setUsuarios(usuarios);
        return;
      }

    }catch(err){
        MostrarAlerta();
        setMessage(err.message);
        setStyle('error');
    }

  };

  //Función para poder eliminar un usuario
  const handleDeleteUser = async () =>{
  
    try{
      setLoading(true);
      const { remove, success, messageErr, styleErr} = await EliminarUsuario(selectedUserId);
      if(success){
        MostrarAlerta();
        setMessage(messageErr);
        setStyle(styleErr);
        if (remove) {
          setTimeout(() => {
            handleCloseDialogUser();
          }, 1000)
          handleconsultarUsuarios();
          return;
        }
        return;
      }

    }catch(err){
      MostrarAlerta();
      setMessage("Ocurrio un erro al eliminar el usuario");
      setStyle("error");
    }finally {
      setLoading(false);
    }

    
  };

  const truncateText =(text, max) =>{
    if(text.lenght <= max){ 
      return text;

    }
    return text.slice(0, max)+ '...';
  };
  //Función para mostrar la Alerta de validación
  const MostrarAlerta = () => {
      setOpenAlert(true);
      setTimeout(() => {
        setOpenAlert(false);
      }, 3000)
  };

  const handleOpenDialogUser = (id, name) =>{
     setSelectedUserId(id);
     setName(name);
    setOpenDialogUser(true);
  };

  const handleCloseDialogUser = () =>{
    setOpenDialogUser(false);
  };

  const handleOpneDialogEditUser =(id, name, email, phone_number, user_type, password, rol, session) =>{
    setSelectedUserId(id);
    setName(name);
    setEmail(email);
    setPhoneNumber(phone_number);
    setUserType(user_type);
    setPassword(password);
    setRol(rol);
    setSession(session)
    setOpenDialogEditUser(true);
  };
  const handleCloseDialogEditUser =() =>{
    setOpenDialogEditUser(false);
  };

  function NoHayRegistros() {
    if (usuarios.length <= 0) {
        return (
          
          <Box 
          sx={{
             textAlign:'center',
             display: "flex",
             justifyContent: "center",
             alignItems: "center",
           }}>
               <Box>
               <AnyUserIcon color="disabled" sx={{ fontSize: 100,}}/>
               <Typography  variant="h3" component="h2">No hay ningún registro</Typography>
               </Box>
              
 
         </Box>
        )
    }
  }
   //Función para poder exportar a PDF
  handleExportPDF = () => {
    if(!showRow){
      const doc = new jsPDf();
      doc.setFontSize(15);
      doc.text('Datos de Usuarios en RenovArte', doc.internal.pageSize.getWidth() /2, 20, {align:'center'})
      doc.addImage(logo, 'PNG', doc.internal.pageSize.getWidth() / 2-95, 9, 50, 18);
      autoTable(doc, { html: '#my-table', startY:40 })
      doc.save('RenovArte-Usuarios.pdf');
      updateRowState(true);
    }
    

};

  return (
    <div>
      
    {isLoading ? <Spinner/>
      :
      showTable ? <NoHayRegistros/>
      :    
      <Table 
        id="my-table"
        stickyHeader 
        aria-label="sticky table"
        sx={{
          whiteSpace: "nowrap",
        }}
    >
      <TableHead>
        <TableRow>
          <TableCell>
            <Typography color="textSecondary" variant="h6">
              Nombre
            </Typography>
          </TableCell>
          <TableCell>
            <Typography color="textSecondary" variant="h6">
              Correo Electrónico
            </Typography>
          </TableCell>
          <TableCell>
            <Typography color="textSecondary" variant="h6">
              Teléfono
            </Typography>
          </TableCell>
          {!showRow ?
          <></>
          :
          <TableCell>
            <Typography color="textSecondary" variant="h6">
              Tipo de Usuario
            </Typography>
          </TableCell>
          }

         {!showRow ?
          <></>
          :
         
          <TableCell>
            <Typography color="textSecondary" variant="h6">
              Contraseña
            </Typography>
          </TableCell>
          }

          <TableCell align="right">
            <Typography color="textSecondary" variant="h6">
              Rol
            </Typography>
          </TableCell>

          {!showRow ? 
          <></>
          :
          <TableCell align="right">
            <Typography color="textSecondary" variant="h6">
              Sesión
            </Typography>
          </TableCell>
          }

         {!showRow ? 
          <></>
          :
         <TableCell align="right">
            <Typography color="textSecondary" variant="h6">
              Eliminar
            </Typography>
          </TableCell>
         }

          {!showRow ? 
          <></>
          :
          <TableCell align="right">
            <Typography color="textSecondary" variant="h6">
              Editar
            </Typography>
          </TableCell>
          }

        </TableRow>
      </TableHead>
      {<TableBody>
        {usuarios.map((user) => (
          <TableRow key={user.name}>
            <TableCell>
              <Typography
                sx={{
                  fontSize: "15px",
                  fontWeight: "500",
                }}
              >
                {user.name}
              </Typography>
            </TableCell>
            <TableCell>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "600",
                    }}
                  >
                    {user.email}
                  </Typography>

                  {!showRow ?
                    <></>
                    :
                  <Typography
                    color="textSecondary"
                    sx={{
                      fontSize: "13px",
                    }}
                  >
                      {
                      user.rol === 1 ? 
                       'Tunueva.casa'
                      :user.rol === 2 ?
                       'AMIC'
                      :user.rol === 3 ?
                       'VIP'
                      :user.rol === 4?
                       'ADMIN'
                      :''
                    }
                  </Typography>
                  }
                </Box>
              </Box>
            </TableCell>
            <TableCell>
              <Typography  variant="h6">
                {user.phone_number}
              </Typography>
            </TableCell>

            {!showRow ?
            <></>
            :
            <TableCell>
              <Chip
              sx={{
                pl: "4px",
                pr: "4px",
                backgroundColor: 'secondary.main',
                color: "#fff",
              }}
              size="small"
              label={
              <Typography  variant="h6">
              {user.user_type}
              </Typography>
              }
              >
              
              </Chip>
            
            </TableCell>
            }

            {!showRow ?
            <></>
            :
            <TableCell>
            <Typography  variant="h6">
                {truncateText(user.password, 10)}
              </Typography>
            </TableCell>
            }
           
            <TableCell align="right">
              <Typography variant="h6">
                { !showRow ? 
                  user.rol === 1 ? 
                  'Tunueva.casa'
                 :user.rol === 2 ?
                  'AMIC'
                 :user.rol === 3 ?
                  'VIP'
                 :user.rol === 4?
                  'ADMIN'
                 :''

                 :
                user.rol
                }
                </Typography>
            </TableCell>

            {showRow === false?
            <></>
            :
            <TableCell align="right">
              <Typography variant="h6">{user.session.toString()}</Typography>
            </TableCell>
            }

           {!showRow ?
           <></>
           :
            <TableCell align="right">
              <IconButton variant="outlined" color="error" onClick={()=> handleOpenDialogUser(user.uid, user.name)}><DeleteIcon/></IconButton>
            </TableCell>
           }

            {!showRow ?
              <></>
             :
            <TableCell align="right">
              <IconButton variant="outlined" color="secondary" 
              onClick={()=> handleOpneDialogEditUser(user.uid, user.name, user.email, user.phone_number, user.user_type, user.password, user.rol, user.session)}>
                <EditIcon/>
              </IconButton>
            </TableCell>
            }

          </TableRow>
        ))}
      </TableBody>
      }
    </Table>
    }
    {openAlert && <Alerta openAlert={openAlert} message={message} style={style} />}
    {openDialoguser && <DialogDeleteUser name={name}  open={openDialoguser} handleClose={handleCloseDialogUser} loading={loading} handleDelete={handleDeleteUser} />}
    {openDialogEditUser && <DialogFormUpdateUser 
      id={selectedUserId} 
      name={name} 
      email={email} 
      phonenumber={phonenumber} 
      password={password} 
      userType={userType}
      rol={rol} 
      session={session} 
      open={openDialogEditUser} 
      handleClose={handleCloseDialogEditUser} 
      loading={loading} 
      handleDelete={handleDeleteUser} 
      />
    }
    </div>
  );
  
  
};

export default ExTable;
export function handleconsultarUsuarios() { };
export function handleExportPDF() { };
