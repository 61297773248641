import React, {useState} from "react";
import {Typography, Button, useMediaQuery} from '@mui/material';
const ResponsiveText =({text})=>{
    const [showFullText, setShowFullText] = useState(false);
    const isMobile = useMediaQuery('(max-width:600px)');

    const isShowMore =()=>{
        setShowFullText(!showFullText)
    };

    const renderText = () => {
        if (isMobile && !showFullText) {
          return `${text.slice(0, 50)}...`;
        }
        return text;
      };

    return (
        <div>
            <Typography fontSize={17} variant="h2" component="h2">
                {renderText()}
                {isMobile && (
                <Button onClick={isShowMore}>
                    {showFullText ? 'Mostrar menos': 'Mostrar más'}
                </Button>
                )}
            </Typography>
            
        </div>
    );

};
export default ResponsiveText;