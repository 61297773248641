import React from 'react';
import {Grid, Card, Stack, Container, Box, Typography, Button, useMediaQuery} from '@mui/material';
import ThemeColor from '../../../../components/Theme/Theme';
import { useNavigate } from 'react-router-dom';
import AppBarContent from "../AppBar";
import FooterContent from "../Footer/Footer";
import amicImg from '../../../../assets/images/card-img/RenovArteAMIC.png';
import ImagenAMIC1 from '../../../../assets/images/card-img/ImagenAMIC1.png';
import ImagenAMIC2 from '../../../../assets/images/card-img/ImagenAMIC2.png';
import ScrollAnimation from 'react-animate-on-scroll';

const PropuestaAMIC = () =>{

    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width:600px)');
    const handleLogin =() =>{
        navigate("/Login?vista=AMIC");

    };
    return(

        <div>
        {/*BLOQUE 1 */}
        <header style={{ position: 'relative', width: '100%' }}>
                <link
                    rel="stylesheet"
                    href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"
                />
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css"></link>
                <AppBarContent />

                <div style={{
                    position: 'relative',
                    width: '100%',
                    height: '100%'
                }}>

                    {isMobile ? 
                     <Box sx={{ bgcolor: '#1E386E', height:'100vh' }} />
                     :
                        <img
                        src={amicImg}
                        alt='AMIC'
                        width='100%'
                        className="w-full h-full object-cover"
                    />
                    }
                    <div style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 1 
                    }} />

                    <div style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 2
                    }}>
                        <Container maxWidth='md'>
                            <Typography className='animate__animated animate__bounceInDown  animate__delay-1s' variant="h1" gutterBottom sx={{ mb: 4, fontWeight: "bold", textAlign: "center" }} color='white' >
                                TRANSFORMANDO EL ECOSISTEMA DE LA CONSTRUCCIÓN PARA UN FUTURO SOSTENIBLE 
                            </Typography>
                        </Container>

                        <Container maxWidth="xl">
                            <Box>
                                <Typography className=' animate__animated animate__bounceInUp animate__delay-2s ' variant="body1" gutterBottom sx={{ textAlign: "center", mb: 2 }} color='whitesmoke' >
                                En la Asociación Mexicana de la Industria de la Construcción (AMIC), estamos comprometidos 
                                con la redefinición del ecosistema de la construcción, creando oportunidades de negocio que no 
                                solo son rentables, sino también sostenibles y responsables con el medio ambiente. Nuestra 
                                misión es liderar la transformación hacia prácticas de construcción que respeten y preserven 
                                nuestros recursos naturales, al tiempo que ofrecen soluciones innovadoras y de alta calidad que 
                                satisfacen las demandas de una sociedad cada vez más exigente e informada.
                                </Typography>
                            </Box>
                        </Container>
                        
                    </div>
                </div>

        </header>
        
        <Stack
           direction="column"
           justifyContent="center"
           alignItems="center"
           sx={{
                    marginTop:"100px"
               }}
        
        >
                  
              {/*BLOQUE 2 */}
              <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' delay={400}>
              <Typography variant="h1" gutterBottom sx={{mb:4, fontWeight:"bold", textAlign:"center"}} >
                  CREANDO VALOR SUSTENTABLE PARA LA SOCIEDAD
              </Typography>
              </ScrollAnimation>
              <ScrollAnimation animateIn='bounceInRight' animateOut='bounceOutLeft' delay={450}>
              <Container sx={{mb:4}} >
                  <Typography  variant="subtitle1" gutterBottom sx={{textAlign:"justify", marginRight:"5%", marginLeft:"5%"}} >
                    AMIC está en la vanguardia de las iniciativas que promueven la sostenibilidad en todos los 
                    aspectos de la construcción. Desde el uso de materiales ecológicos hasta la implementación de 
                    tecnologías avanzadas para reducir la huella de carbono, nuestro enfoque está centrado en crear 
                    valor duradero y positivo para la sociedad. Al hacerlo, no solo mejoramos la calidad de nuestras 
                    construcciones, sino que también establecemos nuevos estándares de excelencia y 
                    responsabilidad en la industria.
                  </Typography>
              </Container>
              </ScrollAnimation>
              <ScrollAnimation animateIn='bounce' animateOut='bounceOutLeft' initiallyVisible={false} animateOnce={false} delay={500}>
               <Box sx={{alignContent:"center", mb:17}}>
                                <ThemeColor>
                                   <Button variant="contained" style={{ color: 'white' }}  color="blue" onClick={handleLogin}>
                                            Iniciar Sesión
                                    </Button> 
                                </ThemeColor>
                </Box>
                </ScrollAnimation>

              {/*BLOQUE 3 */}
              <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' delay={650}>
              <Typography variant="h1" gutterBottom sx={{mb:4, fontWeight:"bold", textAlign:"center"}} >
                              PROFESIONALISMO Y COMPROMISO CON LA COMUNIDAD
               </Typography>
                  <Grid 
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{mb:17}}
                  spacing={2}
              >
                  <ScrollAnimation animateIn='flipInY' animateOut='flipOutY' delay={760}>
                  <Grid item 
                      sx={{
                              display: "flex",
                              alignItems: "stretch",
                          }}>
                      <Card 
                      variant="outlined"
                      sx={{
                          maxWidth:450,
                          p: 0,
                      
                      }}
                      >
                      <img src={ImagenAMIC1}  alt="nevacasa.img" height="100%" width="100%"/>
                      </Card>

                  </Grid>
                  </ScrollAnimation>
                  
                  <Grid item >
                      <Container maxWidth="sm"> 
                      <Box>
                          
                          <Typography  variant="subtitle1" gutterBottom sx={{textAlign:"justify"}} >
                            El profesionalismo es fundamental en cada proyecto que lideramos. En AMIC, garantizamos que 
                            todas nuestras actividades y colaboraciones están guiadas por un firme compromiso con la ética, 
                            la transparencia y la excelencia. Este enfoque nos permite construir relaciones sólidas y 
                            confiables con todas nuestras partes interesadas, incluidos socios, clientes y la comunidad en 
                            general.
                          </Typography>
                      </Box>
                      </Container>
                  </Grid>

              </Grid>
              </ScrollAnimation>

                {/*BLOQUE 4 */}
                <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' delay={810}>  
                <Typography variant="h1" gutterBottom sx={{mb:5, fontWeight:"bold", textAlign:"center"}} >
                     COLABORACIÓN CON LÍDERES DE LA INDUSTRIA
                </Typography>   
                <Grid 
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{mb:13}}
                  spacing={2}
              >
                  
                  <Grid item >
                      <Container maxWidth="sm"> 
                      <Box>
                          
                          <Typography  variant="subtitle1" gutterBottom sx={{textAlign:"justify"}} >
                            AMIC no actúa sola. Trabajamos codo a codo con líderes de la industria inmobiliaria y de la 
                            construcción para impulsar cambios significativos que beneficien a todos los niveles de la 
                            sociedad. Estas colaboraciones son esenciales para acelerar la adopción de prácticas sostenibles 
                            en la industria y para garantizar que los beneficios de nuestras innovaciones se compartan 
                            ampliamente.
                          </Typography>
                      </Box>
                      </Container>
                  </Grid>
                  <ScrollAnimation animateIn='flipInY' animateOut='flipOutY' delay={960}>
                  <Grid item 
                      sx={{
                              display: "flex",
                              alignItems: "stretch",
                          }}>
                      <Card 
                      variant="outlined"
                      sx={{
                          maxWidth:450,
                          p: 0,
                      
                      }}
                      >
                      <img src={ImagenAMIC2}  alt="nevacasa.img" height="100%" width="100%"/>
                      </Card>

                  </Grid>
                  </ScrollAnimation>

              </Grid>
              </ScrollAnimation> 
              
              <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' delay={900}>
              <Container  sx={{mb:17}}>
                  <Box  display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{ bgcolor: '#F6641F', marginRight:"5%", marginLeft:"5%", borderRadius:5, }}>
                    <ScrollAnimation animateIn='bounceInRight' mateOut='bounceOutLeft' delay={960}>
                    <Typography variant="h1" gutterBottom sx={{mb:4, fontWeight:"bold", color:"white", paddingTop:"10%", textAlign:"center"}} >
                            INNOVACIÓN Y LIDERAZGO PARA UN FUTURO MEJOR
                    </Typography>

                    <Typography  variant="subtitle1" gutterBottom sx={{textAlign:"center", color:"white", marginRight:"5%", marginLeft:"5%", mb:4}} >
                            AMIC no actúa sola. Trabajamos codo a codo con líderes de la industria inmobiliaria y de la 
                            construcción para impulsar cambios significativos que beneficien a todos los niveles de la 
                            sociedad. Estas colaboraciones son esenciales para acelerar la adopción de prácticas sostenibles 
                            en la industria y para garantizar que los beneficios de nuestras innovaciones se compartan 
                            ampliamente.
                    </Typography>
                    </ScrollAnimation>

                    <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' delay={970}>
                    <Box sx={{paddingBottom:"5%",}}>
                                <ThemeColor>
                                   <Button variant="contained"  style={{ color: '#1E386E', fontWeight:"bold", }}  color="white" onClick={handleLogin}>
                                            Iniciar Sesión
                                    </Button> 
                                </ThemeColor>
                    </Box>
                    </ScrollAnimation>
                  </Box>

              </Container>
              </ScrollAnimation>
        </Stack>
    <FooterContent/>

  </div>
);

};
export default PropuestaAMIC;